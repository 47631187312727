import clsx from "clsx";
import React from "react";
import { FieldError } from "react-hook-form";

type InputErrorProps = {
  error?: FieldError;
  className?: string;
};

export default function InputError({ error, className }: InputErrorProps) {
  const inputErrorClass = clsx(
    "invalid-feedback d-block text-left mt-3",
    className
  );
  if (!error) {
    return null;
  }
  return <div className={inputErrorClass}>{error.message}</div>;
}
