import React from "react";
import {
  FieldValues,
  UseControllerProps,
  useController,
} from "react-hook-form";
import { components } from "react-select";
import Select from "react-select";
import { useDDI, DDIOption } from "../hooks/useDDI";

const customStyles = {
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#29415e" : "#FFF",
    cursor: "pointer",
    fontSize: ".9rem",

    "&:hover": {
      backgroundColor: state.isSelected ? "#29415e" : "#DEEBFF",
    },
  }),

  control: (provided: any, state: any) => ({
    ...provided,
    minHeight: 49,
    borderColor: state.isFocused ? "#80bdff" : "#dfe2eb",
    boxShadow: state.isFocused
      ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
      : "none",
    borderRadius: "1.5rem",
    cursor: "pointer",
  }),

  input: (provided: any) => ({
    ...provided,
    paddingLeft: ".4rem",
    paddingRight: ".4rem",
  }),

  singleValue: (provided: any) => ({
    ...provided,
    fontSize: ".9rem",
    color: "#536580",
    marginRight: ".5rem",
    marginLeft: ".5rem",
  }),
};

const SingleValue = ({ data, ...props }) => (
  //@ts-ignore
  <components.SingleValue {...props} className="d-flex align-items-center">
    <span className={`mr-2 ${data.icon}`} />
    <span>{data.label}</span>
  </components.SingleValue>
);

const Option = ({ data, ...props }) => (
  //@ts-ignore
  <components.Option {...props} className="d-flex align-items-center">
    <span className={`mr-2 ${data.icon}`} />
    <span>{data.label}</span>
  </components.Option>
);

export default function CountrySelect<T extends FieldValues>(
  props: UseControllerProps<T>
) {
  const { ddiOptions } = useDDI();
  const { field } = useController(props);

  function handleSelectChange(option: DDIOption | null) {
    field.onChange(option);
  }

  return (
    <Select<DDIOption>
      options={ddiOptions}
      onChange={handleSelectChange}
      components={{ SingleValue, Option }}
      styles={customStyles}
      value={field.value}
    />
  );
}
