import React, { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { TestFormSchema } from "./TestForm";
import Input from "./Input";
import TextArea from "./TextArea";
import Select from "./Select";
import { useFormAttributes } from "../hooks/useFormAttributes";
import MaskedInput from "./MaskedInput";
import PhoneInput from "./PhoneInput";
import CountrySelect from "./CountrySelect";

export default function AccountForm() {
  const {
    control,
    register,
    watch,
    formState: { errors, isSubmitting },
  } = useFormContext<TestFormSchema>();

  const { accountTypesOptions, userRolesOptions } = useFormAttributes();

  const accountTypeWatch = watch("account_type");
  const ddiWatch = watch("ddi");
  const phoneMask = useMemo(() => {
    if (ddiWatch.value.toLowerCase() === "br") {
      return {
        mask: [{ mask: "(00) 0000-0000" }, { mask: "(00) 00000-0000" }],
      };
    }
    return {
      mask: Number,
      scale: 0,
    };
  }, [ddiWatch]);

  return (
    <div className="col-12 col-lg-8 pt-3">
      <h5 className="text-center h5-poppins purple font-weight-bold pt-4 pb-3">
        Preencha os dados abaixo para receber o contato do nosso Consultor
      </h5>
      <Input error={errors.user_name} {...register("user_name")}>
        Seu nome completo *
      </Input>
      <Select control={control} name="user_role" label="Seu cargo *">
        {userRolesOptions.map((opt, index) => (
          <option key={index} value={opt[1]}>
            {opt[0]}
          </option>
        ))}
      </Select>
      <Input type="email" error={errors.email} {...register("email")}>
        Seu melhor e-mail *
      </Input>
      <Input error={errors.account_name} {...register("account_name")}>
        Nome do estabelecimento *
      </Input>
      <div className="row">
        <div className="col-lg-8 col-xl-8 col-sm-12 col-12">
          <Select
            control={control}
            name="account_type"
            label="Tipo de estabelecimento *"
          >
            {accountTypesOptions.map((opt, index) => (
              <option key={index} value={opt[1]}>
                {opt[0]}
              </option>
            ))}
          </Select>
        </div>
        <div className="col-lg-4 col-xl-4 col-12">
          <MaskedInput
            error={errors.place_quantity}
            mask="000"
            {...register("place_quantity", {
              valueAsNumber: true,
            })}
          >
            {accountTypeWatch === "hostel"
              ? "Número de camas *"
              : "Número de quartos *"}
          </MaskedInput>
        </div>
      </div>
      <PhoneInput
        opts={phoneMask}
        error={errors.phone}
        label="Telefone de contato com DDD*"
        {...register("phone")}
      >
        <CountrySelect control={control} name="ddi" />
      </PhoneInput>

      <TextArea
        control={control}
        name="comment"
        placeholder="Deixe suas dúvidas para nossos consultores"
        className="form-textarea"
        rows={4}
      >
        Você tem alguma dúvida?{" "}
        <span className="font-weight-normal">(Opcional)</span>
      </TextArea>
      {errors.root?.serverError && (
        <div className="alert bg-danger white alert-icon-left h6 font-weight-normal">
          {errors.root.serverError.message}
        </div>
      )}
      <div className="d-flex align-items-center justify-content-center">
        <button
          type="submit"
          className="btn btn-yellow mt-4 px-5"
          disabled={isSubmitting}
        >
          {isSubmitting ? "Enviando..." : "Enviar pedido"}
        </button>
      </div>
    </div>
  );
}
