import React, { useEffect } from "react";
//@ts-ignore
import FeedbackIcon from "./svg/feedback.svg";
import { Outlet, useNavigate } from "react-router-dom";
import useLayout from "./hooks/useLayout";
import useLead from "./hooks/useLead";

export default function FeedbackLayout() {
  const { title } = useLayout();
  const { lead, isFinish } = useLead();
  const navigate = useNavigate();

  useEffect(() => {
    if (!(lead && isFinish)) {
      navigate("/");
    }
  }, [lead, isFinish, navigate]);

  return (
    <div className="success-page">
      <img
        src={FeedbackIcon}
        alt="Ícone representando sucesso"
        className="mb-5 mx-auto"
      />
      <h2 className="dark-purple mb-4 text-center">{title}</h2>
      <Outlet />
    </div>
  );
}
