import clsx from "clsx";
import React, { TextareaHTMLAttributes } from "react";
import {
  FieldValues,
  UseControllerProps,
  useController,
} from "react-hook-form";

import InputError from "./InputError";

type TextAreaProps<T extends FieldValues> = UseControllerProps<T> &
  TextareaHTMLAttributes<HTMLTextAreaElement>;

export default function TextArea<T extends FieldValues>({
  children,
  className,
  ...props
}: TextAreaProps<T>) {
  const {
    field,
    fieldState: { error },
  } = useController(props);
  const inputClasses = clsx("form-control", className, {
    "is-invalid": !!error,
  });
  return (
    <div className="form-group">
      <label htmlFor={props.name}>{children}</label>
      <textarea
        id={props.name}
        className={inputClasses}
        {...props}
        {...field}
      />
      <InputError error={error} />
    </div>
  );
}
