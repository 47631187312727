import React from "react";
import { Product } from "./product";
import clsx from "clsx";
import ToggleSwitch from "./ToggleSwitch";
const iconPms = require("../../../assets/images/icons/product-icons/pms.svg");
const iconChannel = require("../../../assets/images/icons/product-icons/channel.svg");
const iconMotor = require("../../../assets/images/icons/product-icons/reservation.svg");

type ModuleCardProps = {
  product: Product;
  onClick: () => void;
  isActive: boolean;
  shortVersion: boolean;
};
const iconMap = {
  pms: iconPms,
  channel_manager: iconChannel,
  motor: iconMotor,
};
export default function ModuleCard({
  product,
  onClick,
  isActive,
  shortVersion,
}: ModuleCardProps) {
  const cardClasses = clsx("select-product_card", {
    "select-product_card-active": isActive,
    "select-product_card-disabled": !isActive && shortVersion,
    "select-product_card-short": shortVersion,
  });

  return (
    <div className={cardClasses} onClick={onClick}>
      <div>
        <div className="d-flex align-items-center">
          {!shortVersion && (
            <input
              type="checkbox"
              name={product.identifier}
              className="form-checkbox"
              checked={isActive}
              readOnly
            />
          )}
          <img
            src={iconMap[product.identifier]}
            alt={product.title}
            className="select-product_card-img ml-3"
          />
        </div>
        <div>
          <h5 className="select-product_card-title">{product.title}</h5>
          <p className="select-product_card-description">
            {product.description}
          </p>
        </div>
      </div>
      {product.free_trial ? (
        <div className="select-product_card-trial">
          <ToggleSwitch checked readOnly />
          <span className="ml-2">Teste grátis ativado</span>
          <i data-feather="info"></i>
        </div>
      ) : (
        <div className="select-product_card-notrial">
          <span>Teste não disponível</span>
          <i data-feather="info"></i>
        </div>
      )}
    </div>
  );
}
