import React, { createContext, useCallback, useState } from "react";
import { TestFormValues } from "../form/TestForm";
import axios from "axios";
import { trackFormSubmit } from "../../analytics";
import { useNavigate } from "react-router-dom";
import { OTPValues } from "../form/OTPForm";

enum Statuses {
  external,
  repeated_email,
  approved,
  not_verified,
}
enum Situations {
  new_lead,
  active_test,
  inactive_test,
  active_client,
  inactive_client,
}

export enum Options {
  RECOVERY = "recovery",
  NEW = "new",
}

export type LeadResponse = {
  id: number;
  status: keyof typeof Statuses;
  situation: keyof typeof Situations;
  products: {
    id: number;
    identifier: string;
  }[];
  can_create_account: boolean;
  sign_in_url?: string;
};

type LeadContext = {
  form: TestFormValues | null;
  lead: LeadResponse | null;
  createLead: (formValues: TestFormValues) => Promise<void>;
  resendOTP: () => Promise<void>;
  validateOTP: (formVales: OTPValues) => Promise<void>;
  isFinish: boolean;
  makeAction: (option: Options) => Promise<void>;
};

export const LeadContext = createContext({} as LeadContext);

export function LeadProvider({ children }: { children: React.ReactNode }) {
  const [form, setForm] = useState<TestFormValues | null>(null);
  const [lead, setLead] = useState<LeadResponse | null>(null);
  const [isFinish, setIsFinish] = useState(false);
  const navigate = useNavigate();

  function resetPosition() {
    window.scrollTo(0, 0);
  }

  const createLead = useCallback(
    async (formValues: TestFormValues) => {
      try {
        setForm(formValues);
        const { data } = await axios.post<LeadResponse>(
          "/api/test_orders",
          formValues
        );
        setLead(data);
        trackFormSubmit(formValues);

        if (data.status === "external") {
          navigate("/requested");
          setIsFinish(true);
        } else {
          if (data.situation === "new_lead") {
            navigate("/otp");
          } else {
            navigate("/choose");
          }
        }

        resetPosition();
      } catch (err) {
        throw err;
      }
    },
    [navigate]
  );

  const resendOTP = useCallback(async () => {
    if (!lead) {
      throw new Error("Invalid Lead object");
    }

    try {
      await axios.post(`/api/test_orders/${lead.id}/send_otp`);
      resetPosition();
    } catch (err) {
      throw err;
    }
  }, [lead]);

  const validateOTP = useCallback(
    async ({ validationCode }: OTPValues) => {
      if (!lead) {
        throw new Error("Invalid Lead object");
      }

      try {
        const { data } = await axios.post<{ sign_in_url: string }>(
          `/api/test_orders/${lead.id}/validate_otp`,
          {
            code: validationCode,
          }
        );
        setLead({ ...lead, ...data });
        setIsFinish(true);
        navigate("/created");
        resetPosition();
      } catch (err) {
        throw err;
      }
    },
    [lead, navigate]
  );

  const makeAction = useCallback(
    async (option: Options) => {
      if (!lead) {
        throw new Error("Invalid Lead object");
      }

      try {
        if (option === Options.NEW) {
          await axios.post(`/api/test_orders/${lead.id}/create_otp`);
          navigate("/otp");
        }

        if (option === Options.RECOVERY) {
          await axios.post(`/api/test_orders/${lead.id}/recovery_account`);
          navigate("/recovery");
          setIsFinish(true);
        }
      } catch (err) {
        throw err;
      }
    },
    [lead, navigate]
  );

  return (
    <LeadContext.Provider
      value={{
        createLead,
        form,
        lead,
        resendOTP,
        validateOTP,
        isFinish,
        makeAction,
      }}
    >
      {children}
    </LeadContext.Provider>
  );
}
