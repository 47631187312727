import React, { useEffect } from "react";
import Header from "../Header";
import useLead from "../hooks/useLead";

export default function Created() {
  const { lead, isFinish } = useLead();

  useEffect(() => {
    let subRedirect: NodeJS.Timeout;
    if (lead && lead.sign_in_url) {
      subRedirect = setTimeout(() => {
        window.location.href = lead.sign_in_url as string;
      }, 4 * 1000); //4 seconds
    }

    return () => {
      if (subRedirect) clearTimeout(subRedirect);
    };
  }, [lead, isFinish]);

  return (
    <>
      <Header
        title={
          lead?.situation === "active_client" ||
          lead?.situation === "inactive_client"
            ? "Sua nova conta foi criada ;)"
            : "Parabéns! Seu teste começa agora ;)"
        }
      />
      {lead?.sign_in_url && (
        <p className="purple text-center mb-3">
          Em alguns segundos, você será direcionado para o sistema. Se não for
          direcionado{" "}
          <a href={lead.sign_in_url} rel="noreferrer noopener">
            clique aqui
          </a>
          .
        </p>
      )}
    </>
  );
}
