import React from "react";
import { AlertCircle } from "react-feather";

export default function FeedbackAlert({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <div className="row justify-content-center">
      <div className="col-md-9">
        <div
          className="alert alert-warning small d-flex gap-1 purple mt-5"
          role="alert"
        >
          <AlertCircle />
          <span>{children}</span>
        </div>
      </div>
    </div>
  );
}
