import React, { useEffect } from "react";
import Header from "../Header";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import Input from "./Input";
import { BeatLoader } from "react-spinners";
import ResendOTPButton from "../ResendOTPButton";
import useLead from "../hooks/useLead";
import { useNavigate } from "react-router-dom";
import { isAxiosError } from "axios";

const OTPSchema = z.object({
  validationCode: z.string().min(1, "Campo obrigatório"),
});

export type OTPValues = z.infer<typeof OTPSchema>;

export default function OTPForm() {
  const { lead, validateOTP } = useLead();
  const navigate = useNavigate();
  const {
    handleSubmit,
    setError,
    register,
    formState: { errors, isSubmitting },
  } = useForm<OTPValues>({
    resolver: zodResolver(OTPSchema),
  });

  useEffect(() => {
    if (!lead) {
      navigate("/");
    }
  }, [lead]);

  async function onSubmit(formValues: OTPValues) {
    try {
      await validateOTP(formValues);
    } catch (err) {
      if (isAxiosError(err)) {
        setError("root.serverError", {
          type: err.response?.statusText,
          message: err.response?.data?.error || "Falha ao validar código",
        });
      } else {
        setError("root.serverError", {
          message: "Falha ao validar código",
        });
      }
    }
  }

  return (
    <>
      <Header title="Estamos quase lá!">
        Enviamos um <b>código</b> para o e-mail informado.
        <br />
        Adicione no campo abaixo para acessar o Hospedin agora mesmo.
      </Header>

      <div className="row justify-content-center">
        <div className="col-12 col-lg-7 col-xl-6">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Input
              error={errors.validationCode}
              {...register("validationCode")}
            >
              Código de verificação
            </Input>
            {errors.root?.serverError && (
              <div className="alert bg-danger white alert-icon-left h6 font-weight-normal">
                {errors.root.serverError.message}
              </div>
            )}
            <button
              type="submit"
              disabled={isSubmitting}
              className="btn btn-block btn-yellow mt-4"
            >
              {isSubmitting ? (
                <>
                  <BeatLoader color="#29415E" size={10} />
                  <span className="d-inline-block ml-2">
                    Processando sua solicitação
                  </span>
                </>
              ) : (
                "Confirmar"
              )}
            </button>

            <ResendOTPButton />
          </form>
        </div>
      </div>
    </>
  );
}
