import React, {
  InputHTMLAttributes,
  ReactNode,
  forwardRef,
  useEffect,
} from "react";
import clsx from "clsx";
import { FieldError, useFormContext } from "react-hook-form";
import { useIMask } from "react-imask";
import InputError from "./InputError";

type MaskedInputProps = InputHTMLAttributes<HTMLInputElement> & {
  error?: FieldError;
  mask: any;
  children: ReactNode;
};

const MaskedInput = forwardRef<HTMLInputElement, MaskedInputProps>(
  ({ error, mask, children, ...props }, ref) => {
    const { setValue } = useFormContext();
    const { ref: maskRef, unmaskedValue } = useIMask({ mask: mask });

    useEffect(() => {
      setValue(props.name as string, unmaskedValue);
    }, [unmaskedValue, props.name]);

    const inputClasses = clsx("form-control", props.className, {
      "is-invalid": !!error,
    });

    return (
      <div className="form-group">
        <label htmlFor={props.name}>{children}</label>
        <input {...props} className={inputClasses} ref={maskRef as any} />
        <InputError error={error} />
      </div>
    );
  }
);

export default MaskedInput;
