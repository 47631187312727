import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { Product } from "../product";

export function useModules() {
  const [modules, setModules] = useState<Product[]>([]);
  const [loading, setLoading] = useState(true);

  const fetchModules = useCallback(async () => {
    const { data } = await axios.get<Product[]>("/api/test_orders/modules");
    if (loading) {
      setModules(data);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchModules();

    return () => {
      setLoading(false);
    };
  }, [fetchModules]);

  return { modules, loading };
}
