import React, { createContext, ReactNode, useCallback, useState } from "react";

type LayoutContextType = {
  description: ReactNode | null;
  title: string | null;
  updateHeader: (title: string, description: ReactNode | null) => void;
};

export const LayoutContext = createContext({} as LayoutContextType);

export function LayoutProvider({ children }: { children: ReactNode }) {
  const [description, setDescription] = useState<ReactNode | null>(null);
  const [title, setTitle] = useState<string | null>("");

  const updateHeader = useCallback(
    (title: string, newDesc: ReactNode | null) => {
      setTitle(title);
      setDescription(newDesc);
    },
    []
  );

  return (
    <LayoutContext.Provider value={{ description, title, updateHeader }}>
      {children}
    </LayoutContext.Provider>
  );
}
