import React from "react";
import Skeleton from "./SkeletonLoading";

export default function ModuleCardLoading() {
  return (
    <div className="select-product_card-loading">
      <Skeleton size="md" type="circle" />
      <Skeleton size="md" type="default" className="mt-3" />
      <Skeleton size="md" type="square" className="mt-3" />
    </div>
  );
}
