import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import TestForm from "./form/TestForm";
import FormLayout from "./FormLayout";
import ChooseForm from "./form/ChooseForm";
import OTPForm from "./form/OTPForm";
import FeedbackLayout from "./FeedbackLayout";
import Created from "./feedbacks/Created";
import Recovery from "./feedbacks/Recovery";
import Requested from "./feedbacks/Requested";

export default function TestRoute() {
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has("module")) {
      urlParams.delete("module");
      window.history.replaceState(
        {},
        document.title,
        window.location.pathname + urlParams.toString()
      );
    }
  }, []);

  return (
    <section className="padding-y-fixed">
      <div className="container">
        <Routes>
          <Route path="/" element={<FormLayout />}>
            <Route index element={<TestForm />} />
            <Route path="/choose" element={<ChooseForm />} />
            <Route path="/otp" element={<OTPForm />} />
          </Route>
          <Route element={<FeedbackLayout />}>
            <Route path="created" element={<Created />} />
            <Route path="recovery" element={<Recovery />} />
            <Route path="requested" element={<Requested />} />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </section>
  );
}

const NotFound = () => {
  window.location.href = "/404";
  return null;
};
