import axios from "axios";
import { useCallback, useEffect, useState } from "react";

//[0]: label, [1]: value
type SelectOption = [string, string];

type FormAttributesResponse = {
  user_roles: SelectOption[];
  account_types: SelectOption[];
};

type FormAttributesState = {
  userRolesOptions: SelectOption[];
  accountTypesOptions: SelectOption[];
};

const INITIAL_USER_ROLES: SelectOption[] = [["» selecione um cargo «", ""]];
const INITIAL_ACCOUNT_TYPE: SelectOption[] = [["» selecione um tipo «", ""]];

export function useFormAttributes() {
  const [formAttributes, setFormAttributes] = useState<FormAttributesState>({
    userRolesOptions: INITIAL_USER_ROLES,
    accountTypesOptions: INITIAL_ACCOUNT_TYPE,
  });
  const [loading, setLoading] = useState(true);

  const fetchFormAttributes = useCallback(async () => {
    const { data } = await axios.get<FormAttributesResponse>(
      "/api/test_orders/form_attributes"
    );
    if (loading) {
      setFormAttributes({
        userRolesOptions: [...INITIAL_USER_ROLES, ...data.user_roles],
        accountTypesOptions: [...INITIAL_ACCOUNT_TYPE, ...data.account_types],
      });
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchFormAttributes();

    return () => {
      setLoading(false);
    };
  }, [fetchFormAttributes]);

  return { ...formAttributes, loading };
}
