import React, { useEffect, useState } from "react";
import clsx from "clsx";
import useLead from "./hooks/useLead";

export default function ResendOTPButton() {
  const [visible, setVisible] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [sended, setSended] = useState(false);
  const { resendOTP } = useLead();

  const canSend = !(submitting || sended);

  useEffect(() => {
    const subVisible = setTimeout(() => {
      setVisible(true);
    }, 18 * 1000); //18 seconds

    return () => clearTimeout(subVisible);
  }, []);

  async function handleSubmit() {
    if (canSend) {
      setSubmitting(true);
      try {
        await resendOTP();
        setSended(true);
      } finally {
        setSubmitting(false);
      }
    }
  }

  if (!visible) {
    return null;
  }

  const linkClasses = clsx("text-center col-12 col-lg-12 mt-3 small", {
    "cursor-pointer": canSend,
    "blue-link": canSend,
    purple: !canSend,
  });

  return (
    <p className={linkClasses} onClick={handleSubmit}>
      {submitting
        ? "Enviando..."
        : sended
        ? "Código reenviado com sucesso"
        : "Reenviar código de verificação"}
    </p>
  );
}
