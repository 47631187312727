import clsx from "clsx";
import React, { SelectHTMLAttributes } from "react";
import {
  FieldValues,
  UseControllerProps,
  useController,
} from "react-hook-form";

import InputError from "./InputError";

type SelectProps<T extends FieldValues> = UseControllerProps<T> &
  SelectHTMLAttributes<HTMLSelectElement> & { label: React.ReactNode };

export default function Select<T extends FieldValues>({
  label,
  className,
  ...props
}: SelectProps<T>) {
  const {
    field,
    fieldState: { error },
  } = useController(props);
  const inputClasses = clsx("form-control", className, {
    "is-invalid": !!error,
  });
  return (
    <div className="form-group">
      <label htmlFor={props.name}>{label}</label>
      <select id={props.name} className={inputClasses} {...props} {...field} />
      <InputError error={error} />
    </div>
  );
}
