import React from "react";
import useLayout from "./hooks/useLayout";
import { Outlet } from "react-router-dom";

export default function FormLayout() {
  const { title, description } = useLayout();
  return (
    <>
      <h1 className="dark-purple mb-3 text-center h1">{title}</h1>
      <div className="row justify-content-center">
        <p className="purple mb-5 text-center col-12 col-lg-9">{description}</p>
      </div>
      <Outlet />
    </>
  );
}
