import React, {
  InputHTMLAttributes,
  ReactNode,
  forwardRef,
  useEffect,
} from "react";
import clsx from "clsx";
import { FieldError, useFormContext } from "react-hook-form";
import { useIMask } from "react-imask";
import InputError from "./InputError";

type PhoneInputProps = InputHTMLAttributes<HTMLInputElement> & {
  error?: FieldError;
  opts: any;
  label?: string;
  children: ReactNode;
};

const PhoneInput = forwardRef<HTMLInputElement, PhoneInputProps>(
  ({ error, opts, label, children, ...props }, ref) => {
    const { setValue } = useFormContext();
    const { ref: maskRef, unmaskedValue } = useIMask(opts);

    useEffect(() => {
      setValue(props.name as string, unmaskedValue);
    }, [unmaskedValue, props.name]);

    const inputClasses = clsx("form-control", props.className, {
      "is-invalid": !!error,
    });

    return (
      <div className="form-group">
        <label htmlFor={props.name}>{label}</label>
        <div className="row">
          <div className="col-5 col-lg-4">{children}</div>
          <div className="col-7 col-lg-8">
            <input {...props} className={inputClasses} ref={maskRef as any} />
          </div>
        </div>
        <InputError error={error} />
      </div>
    );
  }
);

export default PhoneInput;
