import React, { useState } from "react";
import Header from "../Header";
import z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm } from "react-hook-form";
import ModuleSelection from "./ModuleSelection";
import AccountForm from "./AccountForm";
import { isAxiosError } from "axios";
import { useModules } from "../hooks/useModules";
import ModuleCardLoading from "../ModuleCardLoading";
import { trackFormSelected } from "../../analytics";
import useLead from "../hooks/useLead";
import { useSearchParams } from "react-router-dom";

const Schema = z
  .object({
    identifier: z.string().array().min(1, "Selecione pelo menos um produto"),
    user_name: z.string().min(3, "Nome deve ter no mínimo 3 caracteres"),
    user_role: z.string({
      required_error: "Você deve selecionar um cargo",
    }),
    email: z.string().email("Formato de e-mail inválido"),
    account_name: z
      .string()
      .min(3, "Nome do estabelecimento deve ter no mínimo 3 caracteres"),
    place_quantity: z
      .number({
        invalid_type_error: "Campo obrigatório",
      })
      .min(1, "Quantidade mínima 1")
      .max(600, "Quantidade máxima 600"),
    ddi: z.object({
      label: z.string(),
      value: z.string(),
      icon: z.string(),
    }),
    phone: z.string().min(3, "Telefone inválido"),
    comment: z.string().nullish(),
    account_type: z.string({
      required_error: "Você deve selecionar um tipo de estabelecimento",
    }),
  })
  .superRefine((data, ctx) => {
    if (
      data.ddi?.value.toLocaleLowerCase() === "br" &&
      data.phone.length < 10
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Telefone inválido.",
        path: ["phone"],
      });
    }
  })
  .transform((data) => ({
    ...data,
    ddi: data.ddi.value,
  }));

export type TestFormSchema = z.input<typeof Schema>;
export type TestFormValues = z.output<typeof Schema>;

export default function TestForm() {
  const [searchParams] = useSearchParams();
  const initialModule = searchParams.get("module");
  const { createLead } = useLead();
  const [showForm, setShowForm] = useState(false);

  const formMethods = useForm<TestFormSchema, any, TestFormValues>({
    resolver: zodResolver(Schema),
    defaultValues: {
      identifier: initialModule ? [initialModule] : [],
      ddi: {
        label: "+55",
        value: "BR",
        icon: "flag-icon flag-icon-br",
      },
    },
  });
  const modulesWatch = formMethods.watch("identifier");

  const { control, handleSubmit } = formMethods;
  const { loading, modules } = useModules();

  function showNextStep() {
    setShowForm(true);
    trackFormSelected(modulesWatch, modules);
  }

  async function onSubmit(formValues: TestFormValues) {
    try {
      await createLead(formValues);
    } catch (err) {
      if (isAxiosError(err)) {
        formMethods.setError("root.serverError", {
          type: err.response?.statusText,
          message: err.response?.data?.error || "Falha ao enviar formulário",
        });
      } else {
        formMethods.setError("root.serverError", {
          message: "Falha ao enviar formulário",
        });
      }
    }
  }

  return (
    <FormProvider {...formMethods}>
      {modulesWatch.includes("pms") ? (
        <Header title="Testar o PMS grátis">
          Adicione outros produtos e aguarde nosso Consultor
        </Header>
      ) : (
        <Header title="Fale com o Consultor">
          Escolha abaixo os produtos que deseja conhecer
        </Header>
      )}

      <div className="row justify-content-center">
        <form
          id="test-form"
          className="row px-4 justify-content-center"
          onSubmit={handleSubmit(onSubmit)}
        >
          {loading ? (
            <div className="select-product_loading">
              <ModuleCardLoading />
              <ModuleCardLoading />
              <ModuleCardLoading />
            </div>
          ) : (
            <>
              <ModuleSelection
                modules={modules}
                control={control}
                shortVersion={showForm}
              />

              {!showForm ? (
                <div className="col-12 col-lg-7 col-xl-6 text-center">
                  <button
                    type="button"
                    onClick={showNextStep}
                    className="btn btn-yellow mt-5 px-5"
                    disabled={!modulesWatch.length}
                  >
                    Avançar
                  </button>
                </div>
              ) : (
                <AccountForm />
              )}
            </>
          )}
        </form>
      </div>
    </FormProvider>
  );
}
