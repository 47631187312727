import clsx from "clsx";
import React, { useState } from "react";

type ToggleSwitchProps = {
  onToggle?: () => void;
  checked?: boolean;
  readOnly?: boolean;
};

export default function ToggleSwitch({
  onToggle,
  checked,
  readOnly,
}: ToggleSwitchProps) {
  const [toggle, setToggle] = useState(checked);

  const sliderClass = clsx("toggle-switch_slider", {
    "toggle-switch_slider-animation": toggle,
  });

  const handleClick = () => {
    if (!readOnly) {
      setToggle(!toggle);
      if (onToggle) {
        onToggle();
      }
    }
  };

  return (
    <div className="toggle-switch_container ">
      <div className="toggle-switch_item p-1" onClick={handleClick}>
        <div className={sliderClass}></div>
      </div>
    </div>
  );
}
