import React from "react";
import Header from "../Header";

export default function Recovery() {
  return (
    <>
      <Header title="Dados enviados!" />
      <p className="purple text-center mb-3">
        Nos próximos minutos você receberá um e-mail para recuperar sua senha de
        acesso.
      </p>
    </>
  );
}
