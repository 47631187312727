import React, { useEffect } from "react";
import Header from "../Header";
import { z } from "zod";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import clsx from "clsx";
import { LeadResponse, Options } from "../contexts/LeadContext";
import useLead from "../hooks/useLead";
import { isAxiosError } from "axios";
import { useNavigate } from "react-router-dom";

const OptionsDesc = {
  recovery: (lead: LeadResponse): string =>
    lead.situation === "active_test" || lead.situation === "inactive_test"
      ? "Sim, envie os dados para o meu e-mail"
      : "Acessar conta existente",
  new: (lead: LeadResponse): string =>
    lead.situation === "inactive_test"
      ? "Sim, quero testar novamente"
      : "Criar uma conta nova",
} as const;

const ChooseSchema = z.object({
  option: z.nativeEnum(Options, {
    required_error: "Você deve escolher uma opção antes de avançar",
  }),
});

const OptionsRenderMap = {
  recovery: (lead: LeadResponse): boolean =>
    ["active_client", "inactive_client", "active_test"].includes(
      lead.situation
    ) ||
    (lead.situation === "inactive_test" && !lead.can_create_account),
  new: (lead: LeadResponse): boolean => lead.can_create_account,
};

type ChooseSchemaValues = z.infer<typeof ChooseSchema>;

export default function TestOptions() {
  const { makeAction, lead } = useLead();
  const navigate = useNavigate();

  const options = Object.values(Options).filter(
    (opt) => lead && OptionsRenderMap[opt](lead)
  );

  const {
    handleSubmit,
    control,
    setError,
    formState: { errors, isSubmitting },
  } = useForm<ChooseSchemaValues>({
    resolver: zodResolver(ChooseSchema),
  });

  useEffect(() => {
    if (!lead) {
      navigate("/");
    }
  }, [lead]);

  async function onSubmit(data: ChooseSchemaValues) {
    try {
      await makeAction(data.option);
    } catch (err) {
      if (isAxiosError(err)) {
        setError("root.serverError", {
          type: err.response?.statusText,
          message: err.response?.data?.error || "Falha ao realizar ação",
        });
      } else {
        setError("root.serverError", {
          message: "Falha ao realizar ação",
        });
      }
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="text-center">
      <Header title="Falta pouco!">
        {(lead?.situation === "active_client" ||
          lead?.situation === "inactive_client") && (
          <>
            Percebemos que <b>você já é nosso cliente!</b>
            <br />
            Escolha uma das opções abaixo para seguir:
          </>
        )}

        {(lead?.situation === "active_test" ||
          (lead?.situation === "inactive_test" &&
            !lead.can_create_account)) && (
          <>
            Percebemos que você <b>já possui uma conta conosco!</b>
            <br />
            Gostaria de receber os dados de acesso em seu e-mail?
          </>
        )}

        {lead?.situation === "inactive_test" && lead?.can_create_account && (
          <>
            Percebemos que <b>você já testou nosso sistema!</b>
            <br />
            Gostaria de testar novamente?
          </>
        )}
      </Header>

      <Controller
        name="option"
        control={control}
        render={({ field }) => (
          <ul className="d-flex flex-column flex-md-row justify-content-center list-unstyled gap-4">
            {options.map((opt) => {
              const cardClasses = clsx("option-card", {
                active: field.value === opt,
              });
              return (
                <li
                  key={opt}
                  onClick={() => field.onChange(opt)}
                  className={cardClasses}
                >
                  {lead && OptionsDesc[opt](lead)}
                </li>
              );
            })}
          </ul>
        )}
      />

      {errors.option && (
        <div className="invalid-feedback d-block text-center mt-3">
          {errors.option.message}
        </div>
      )}

      {errors.root?.serverError && (
        <div className="alert bg-danger white alert-icon-left h6 font-weight-normal">
          {errors.root.serverError.message}
        </div>
      )}

      <button
        type="submit"
        className="btn btn-yellow mt-5 px-5"
        disabled={isSubmitting}
      >
        {isSubmitting && "Carregando..."}

        {!isSubmitting &&
          (lead?.situation === "active_test" ||
            (lead?.situation === "inactive_test" &&
              !lead.can_create_account)) &&
          "Enviar dados"}

        {!isSubmitting &&
          (lead?.situation === "active_client" ||
            lead?.situation === "inactive_client" ||
            (lead?.situation === "inactive_test" && lead.can_create_account)) &&
          "Confirmar"}
      </button>
    </form>
  );
}
