import clsx from "clsx";
import React, { InputHTMLAttributes, forwardRef } from "react";
import { FieldError } from "react-hook-form";

import InputError from "./InputError";

type InputProps = InputHTMLAttributes<HTMLInputElement> & {
  error?: FieldError;
};

const Input = forwardRef<HTMLInputElement, InputProps>(function (
  { error, children, className, ...props },
  ref
) {
  const inputClasses = clsx("form-control", className, {
    "is-invalid": !!error,
  });
  return (
    <div className="form-group">
      <label htmlFor={props.name}>{children}</label>
      <input id={props.name} className={inputClasses} ref={ref} {...props} />
      <InputError error={error} />
    </div>
  );
});

export default Input;
