import axios from "axios";
import { useCallback, useEffect, useState } from "react";

type DDIListResponse = [string, string][];

export type DDIOption = {
  label: string;
  value: string;
  icon: string;
};

export function useDDI() {
  const [ddiOptions, setDDIOptions] = useState<DDIOption[]>([]);
  const [loading, setLoading] = useState(true);

  const fetch = useCallback(async () => {
    const { data } = await axios.get<DDIListResponse>(
      "/api/test_orders/ddi_list"
    );
    if (loading) {
      setDDIOptions(
        data.map((ddi) => ({
          value: ddi[1],
          label: ddi[0].split(" ")[1],
          icon: `flag-icon flag-icon-${ddi[1].toLowerCase()}`,
        }))
      );
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetch();

    return () => {
      setLoading(false);
    };
  }, [fetch]);

  return { ddiOptions, loading };
}
