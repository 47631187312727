import React from "react";
import ModuleCard from "../ModuleCard";
import {
  UseControllerProps,
  useController,
  useFormContext,
} from "react-hook-form";
import { TestFormSchema } from "./TestForm";
import InputError from "./InputError";
import { Product } from "../product";

type ModuleSelectionProps = Omit<UseControllerProps<TestFormSchema>, "name"> & {
  shortVersion: boolean;
  modules: Product[];
};

export default function ModuleSelection(props: ModuleSelectionProps) {
  const {
    field,
    fieldState: { error },
  } = useController({
    name: "identifier",
    control: props.control,
  });

  const { setValue } = useFormContext<TestFormSchema>();

  function handleCardClick(type: string) {
    if (!props.shortVersion) {
      if (isCardActive(type)) {
        setValue(
          "identifier",
          field.value.filter((item) => item !== type),
          { shouldValidate: true }
        );
      } else {
        setValue("identifier", [...field.value, type], {
          shouldValidate: true,
        });
      }
    }
  }

  function isCardActive(type: string) {
    return field.value.includes(type);
  }

  return (
    <>
      <div className="select-product">
        {props.modules.map((product) => (
          <ModuleCard
            product={product}
            key={product.id}
            onClick={() => handleCardClick(product.identifier)}
            isActive={isCardActive(product.identifier)}
            shortVersion={props.shortVersion}
          />
        ))}
      </div>
      <InputError error={error} className="text-center" />
    </>
  );
}
