import React from "react";
import Header from "../Header";

export default function Requested() {
  return (
    <>
      <Header title="Dados enviados!" />
      <p className="purple text-center mb-3">
        Obrigado. Nossos Consultores farão contato com você em breve.
        <br />
        Atendemos de segunda à sexta das 9 às 12 e das 13:30 às 18.
      </p>
    </>
  );
}
