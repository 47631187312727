import React, { useEffect } from "react";
import useLayout from "./hooks/useLayout";

type HeaderProps = {
  title: string;
  children?: React.ReactNode;
};

export default function Header({ title, children }: HeaderProps) {
  const { updateHeader } = useLayout();
  useEffect(() => {
    updateHeader(title, children);
  });

  return null;
}
