import React from "react";
import { BrowserRouter } from "react-router-dom";
import Route from "./TestRequest/Route";
import { LayoutProvider } from "./TestRequest/contexts/LayoutContext";
import { LeadProvider } from "./TestRequest/contexts/LeadContext";

export default function TestRequest() {
  return (
    <BrowserRouter basename="/teste-gratis">
      <LayoutProvider>
        <LeadProvider>
          <Route />
        </LeadProvider>
      </LayoutProvider>
    </BrowserRouter>
  );
}
