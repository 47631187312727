import { Product } from "./TestRequest/product";
import { TestFormValues } from "./TestRequest/form/TestForm";

// @ts-ignore
window.dataLayer = window.dataLayer || [];

export function trackFormSelected(
  selectedModules: string[],
  modules: Product[]
) {
  let modulesSelectionMap = {};
  modules.forEach((mod, index) => {
    if (selectedModules.includes(mod.identifier)) {
      modulesSelectionMap[`card_selecionado_0${index + 1}`] = mod.title;
    }
  });

  // @ts-ignore
  window.dataLayer.push({
    event: "form_selected",
    ...modulesSelectionMap,
  });
}

export function trackFormSubmit(data: TestFormValues) {
  // @ts-ignore
  window.dataLayer.push({
    event: "form_submit",
    ...data,
  });
}
