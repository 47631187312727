import React from "react";

type SkeletonProps = {
  type?: "circle" | "square" | "default";
  size?: "sm" | "md" | "lg";
  className?: string;
};

export default function Skeleton({
  type = "default",
  size,
  className,
}: SkeletonProps) {
  return (
    <div
      className={`skeleton ${type && `skeleton_${type}`} ${
        size && `skeleton_${type}-${size} ${className && `${className}`}`
      }`}
    />
  );
}
